import { gql } from '__generated__';

export const USER_FRAGMENT = gql(`
  fragment User on User {
    id
    firstName
    lastName
    fullName
    friendlyName
    email
    enabled
    userExpiry
    presentationSingle {
      ... on presentation_Entry {
        id
        url
        brandSingle {
          slug
          id
          title
        }
      }
    }
  }
`);

export const AUTHENTICATE_FRAGMENT = gql(`
  fragment Authenticate on Auth {
      jwt
      jwtExpiresAt
      refreshToken
      refreshTokenExpiresAt
      user {
        ...User
      }
  }
`);

export const REFRESH_TOKEN_MUTATION = gql(`
  mutation RefreshToken($refreshToken: String!) {
    authenticate: refreshToken(refreshToken: $refreshToken) {
      ...Authenticate
    }
  }
`);

export const AUTHENTICATE_MUTATION = gql(`
  mutation Authenticate($email: String!, $password: String!) {
    authenticate(
      email: $email
      password: $password
    ) {
      ...Authenticate
    }
  }
`);

export const VIEWER_QUERY = gql(`
  query Viewer {
    viewer {
      ...User
    }
  }
`);

export const DELETE_REFRESH_TOKENS_MUTATION = gql(`
  mutation DeleteRefreshTokens {
    deleteRefreshTokens
  }
`);
