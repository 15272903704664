import { GraphQLFormattedError } from 'graphql';
import { ResponseCookie } from 'next/dist/compiled/@edge-runtime/cookies';
import { User } from '../parseUser';

export type ResponseCookieSettings = [string, string, Partial<ResponseCookie>][];

export interface AuthTokenResponse {
  cookies?: ResponseCookieSettings;
}

export interface AuthenticateUserSuccess extends AuthTokenResponse {
  success: true;
  user: User;
}

export interface AuthenticateUserFailure {
  success: false;
  error?: boolean | Error;
  errors?: readonly GraphQLFormattedError[];
}

export type AuthenticateUserResponse = AuthenticateUserSuccess | AuthenticateUserFailure;

export const isAuthSuccess = (res: AuthenticateUserResponse): res is AuthenticateUserSuccess => {
  return res.success && !!res.user;
};
