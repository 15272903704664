import { GTM_ID } from '@/lib/constants';
import { sendGTMEvent } from '@next/third-parties/google';
import React from 'react';

const enabled = !!GTM_ID;

export default function useSeoEvent() {
  return {
    trigger: React.useCallback(
      (event: string, value?: unknown, other?: Record<string, unknown>) => {
        if (!enabled) return;

        sendGTMEvent({ event, value, ...other });
      },
      []
    ),
  };
}
