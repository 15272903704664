export const AUTH_ORIGIN = process.env.AUTH_ORIGIN || 'http://localhost:3000';
export const COOKIE_DOMAIN = process.env.COOKIE_DOMAIN || new URL(AUTH_ORIGIN).hostname;
export const LOGIN_REDIRECT_COOKIE_NAME = '__login_redirect';

export const LOGIN_DISABLED = !!process.env.NEXT_PUBLIC_FEATURE_LOGIN_DISABLED;

// Set the constants for the auth middleware
export const LOGIN_ENDPOINT = new URL(process.env.LOGIN_ENDPOINT || '/login/', AUTH_ORIGIN);
export const LOGOUT_ENDPOINT = process.env.LOGOUT_ENDPOINT || '/api/auth/logout/';
export const AUTH_ENDPOINT = new URL(process.env.AUTH_ENDPOINT || '/api/auth/', AUTH_ORIGIN);
export const LOGIN_REDIRECT_PARAM = process.env.LOGIN_REDIRECT_PARAM || 'redirect';
export const JWT_TOKEN_COOKIE_NAME = process.env.JWT_TOKEN_COOKIE_NAME || '__auth_token';
export const JWT_REFRESH_TOKEN_COOKIE_NAME =
  process.env.JWT_REFRESH_TOKEN_COOKIE_NAME || '__auth_token_refresh';
