'use client';

import { IS_DEV } from '@/lib/constants';
import { colorVars } from '@/theme/themeVars.css';
import { CircularProgress, Dialog, TextField } from '@mui/material';
import { useRouter, useSearchParams } from 'next/navigation';
import React from 'react';
import { LOGIN_REDIRECT_PARAM } from '../Auth/authConstants';
import { UserErrorMessages } from '../Auth/parseUser';
import useLogin from '../Auth/useLogin';
import Button from '../ui/Button';
import Container from '../ui/Container';
import Flex from '../ui/Flex';
import Txt from '../ui/Txt';
import { ClientLoginSettings } from './parseClientLoginSettings';

const USE_TEST_USER = IS_DEV && true; // process.env.NEXT_PUBLIC_USE_TEST_USER;

const TEST_USER_EMAIL = USE_TEST_USER && process.env.NEXT_PUBLIC_TEST_USER_EMAIL;
const TEST_USER_PW = USE_TEST_USER && process.env.NEXT_PUBLIC_TEST_USER_PW;

export interface LoginFormProps {
  settings?: ClientLoginSettings | null;
  header?: React.ReactNode;
}

const LoginForm = ({ header, settings }: LoginFormProps) => {
  const router = useRouter();
  const params = useSearchParams();

  const { login, loading, error, errors } = useLogin();

  const [email, setEmail] = React.useState(TEST_USER_EMAIL || '');
  const [password, setPassword] = React.useState(TEST_USER_PW || '');

  const [isRedirecting, setIsRedirecting] = React.useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const loginResponse = await login({ email, password });

    if (!loginResponse) return;

    const requestedPage = params.get(LOGIN_REDIRECT_PARAM);

    // Redirect to the requested page or the user's home page
    const redirect = requestedPage || loginResponse?.user?.homeUri || settings?.defaultRedirect;

    setEmail('');
    setPassword('');
    setIsRedirecting(true);

    if (redirect) {
      router.push(redirect);
    } else {
      router.refresh();
    }
  };

  const showErrors = error || !!errors?.length;
  const errorMessages = errors?.length ? errors : [UserErrorMessages.UNKNOWN];
  const showLoading = loading || isRedirecting;

  return (
    <Container maxWidth="md">
      <Dialog
        open
        maxWidth="sm"
        slotProps={{
          backdrop: { sx: { bgcolor: 'transparent' } },
        }}
        PaperProps={{
          elevation: 10,
          sx: {
            bgcolor: 'background.paper',
            borderRadius: 6,
            overflow: 'hidden',
          },
        }}>
        <form onSubmit={handleSubmit} noValidate>
          <Flex direction="column" gap={4}>
            <Flex direction="column" paddingX={4} paddingTop={4} rowGap={2}>
              {header}
              <Txt variant="h6" bold color="primary">
                {settings?.heading}
              </Txt>
            </Flex>
            <Flex direction="column" gap={2} paddingX={4}>
              <TextField
                disabled={showLoading}
                label="Email"
                variant="outlined"
                required
                type="email"
                autoComplete="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                disabled={showLoading}
                label="Password"
                variant="outlined"
                required
                type="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Flex>
            {showErrors && (
              <Flex paddingX={4}>
                {errorMessages.map((e) => (
                  <Txt key={e} color="error" variant="small">
                    {e}
                  </Txt>
                ))}
              </Flex>
            )}
            <Flex
              direction="column"
              gap={2}
              sx={{
                bgcolor: colorVars.warmGrey100,
                padding: 4,
              }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                endIcon={showLoading && <CircularProgress color="inherit" size={16} />}
                disabled={showLoading}>
                {loading ? 'Logging in...' : isRedirecting ? 'Redirecting...' : 'Log in'}
              </Button>
            </Flex>
          </Flex>
        </form>
      </Dialog>
    </Container>
  );
};

export default LoginForm;
