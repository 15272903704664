import { createFragmentParser } from '@liquorice/gql-utils';
import { excludeEmptyTypedElements } from '@liquorice/utils';
import type { Maybe, UserFragment } from '__generated__/graphql';
import { USER_FRAGMENT } from './auth.gql';

export enum UserErrorMessages {
  EXPIRED = 'Your account has expired',
  INVALID = 'Invalid user',
  UNKNOWN = 'There was an error validating your account',
}

export interface User {
  id: string;
  email: string;
  fullName?: string;
  firstName?: string;
  lastName?: string;
  friendlyName?: string;
  homeUri?: string;
  expiry?: Date;
}

export interface UserError {
  message: UserErrorMessages;
}

export const isUser = (data?: Maybe<User | UserFragment | UserError>): data is User => {
  if (!data) return false;
  const user = data as User;
  return !!user.id /* && !!user.fullName */ && !!user.email;
};

export const parseUser = createFragmentParser(USER_FRAGMENT, (data): User | UserError => {
  const presentation = excludeEmptyTypedElements(data?.presentationSingle).at(0);
  const homeUri = presentation?.url || '';
  const userExpiry = data?.userExpiry ? new Date(data.userExpiry) : undefined;

  const maybeUser: User = {
    id: data?.id || '',
    email: data?.email || '',
    fullName: data?.fullName || '',
    firstName: data?.firstName || '',
    lastName: data?.lastName || '',
    friendlyName: data?.friendlyName || '',
    homeUri,
    expiry: userExpiry,
  };

  return isUser(maybeUser) ? maybeUser : { message: UserErrorMessages.INVALID };
});

export type ValidateUserResponse =
  | {
      success: true;
      user: User;
    }
  | {
      success: false;
      error?: UserError;
    };

export const validateUser = (user: User | UserError): ValidateUserResponse => {
  if (!isUser(user)) return { success: false, error: user };

  const hasExpired = user.expiry ? user.expiry.getTime() < Date.now() : false;
  console.log('hasExpired', hasExpired, user.expiry);
  if (hasExpired) {
    return {
      success: false,
      error: { message: UserErrorMessages.EXPIRED },
    };
  }

  return { success: true, user };
};
