'use client';

import React from 'react';
import { AuthContext, AuthState, AuthStoreObject, createAuthStore } from './useAuth';

type AuthProviderProps = React.PropsWithChildren<{
  value?: Partial<AuthState>;
}>;

const AuthProvider = ({ value, children }: AuthProviderProps) => {
  const contextRef = React.useRef<AuthStoreObject>();

  if (!contextRef.current) {
    contextRef.current = createAuthStore(value);
  }

  return <AuthContext.Provider value={contextRef.current}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
