import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/@apollo/client-react-streaming/dist/index.cc.cjs");
;
import(/* webpackMode: "eager", webpackExports: ["SimulatePreloadedQuery"] */ "/vercel/path0/frontend/node_modules/@apollo/client-react-streaming/dist/index.cc.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/@liquorice/next-apollo/src/ApolloWrapper/ApolloWrapperInternal.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/@mui/material/Box/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/frontend/node_modules/@mui/material/Stack/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fcomponents%2FLogin%2FLogin.css.ts.vanilla.css%22%2C%22source%22%3A%22LmZtMTA0ZzEgewogIGhlaWdodDogNDVweDsKICBtYXJnaW46IHZhcigtLWVsNDNweDEyKTsKICBkaXNwbGF5OiBmbGV4OwogIGZsZXgtZGlyZWN0aW9uOiByb3c7CiAgYWxpZ24taXRlbXM6IGNlbnRlcjsKICBqdXN0aWZ5LWNvbnRlbnQ6IGZsZXgtc3RhcnQ7Cn0KLmZtMTA0ZzIgewogIG1heC1oZWlnaHQ6IDQ1cHg7CiAgaGVpZ2h0OiAxMDAlOwogIHdpZHRoOiBhdXRvOwp9Ci5mbTEwNGczIHsKICBtYXgtaGVpZ2h0OiA0NXB4OwogIGhlaWdodDogMTAwJTsKICB3aWR0aDogYXV0bzsKfQ%3D%3D%22%7D");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/frontend/src/components/Auth/AuthProvider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/src/components/Login/Login.css.ts");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/frontend/src/components/Login/LoginForm.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/src/components/Section/Section.tsx");
